export const PaymentAndDeliveryTitle = ({lang}) => lang === 'ua'
	? "Оплата і доставка"
	: "Payment and Delivery"
export const PaymentAndDeliveryText = ({lang}) => lang === 'ua'
	? <>


	</>
	: <>

	</>

export const ReturnsAndExchangeTitle = ({lang}) => lang === 'ua'
	? "Повернення та обмін"
	: "Returns"
export const ReturnsAndExchangeText = ({lang}) => lang === 'ua'
	? <>


	</>
	: <>

	</>

export const PublicOfferTitle = ({lang}) => lang === 'ua'
	? "Договір Публічної Оферти"
	: "Public Offer"

export const PrivacyPolicyTitle = ({lang}) => lang === 'ua'
	? "Політика Конфіденційності"
	: "Privacy Policy"
export const PrivacyPolicyText = ({lang}) => lang === 'ua'
	? <>

	</>
	: <>

	</>

export const SizesTitle = ({lang}) => lang === 'ua'
	? "Розмірна сітка"
	: "Size guide"

export const translations = {
	header: {
		aboutUs: {
			ua: "Про Нас",
			en: "About Us",
		},
		shop: {
			ua: "Магазин",
			en: "Shop",
		},
		chat: {
			en: "Chat",
			ua: "Чат",
		}
	},
	main: {
		utp: {
			ua: "«Назва SHTOR походить від мого прізвища та є ідеальним симбіозом цінностей, які батьки заклали у виховання. Батько казав: «Правда - гарний шлях до реального себе, без ілюзій і гарних слів.» Це не завжди легко збагнути. Але що б ми робили, якби у нашому житті не було одночасно і чорного і білого. Саме тому, чорний і білий - основні кольори, які використовуються у позиціонуванні.\nМама ж займає вагоме місце в моєму житті. Вона познайомила мене з мистецтвом, і навчила бачити цей світ прекрасним.\n\nНад ідеєю я замислилась під час перегляду старих родинних фотографій, коли я випадково натрапила на знімок моєї мами. На фото їй тільки 16 років, вона ще не поїхала зі свого маленького рідного села, але вже сповнена віри у себе та майбутнє. Найбільше мене вразив її сильний погляд сповнений цілковитою свободою. І саме цей образ самобутності покладений у бренд.»\n\n-Галина Штор, власниця бренду ",
			en: "The name SHTOR comes from my surname and is a perfect symbiosis of my parents and the values they instilled in me. My father always said: \"The truth is a good way to the real you, without illusions and pretty words.\" This is not always easy to understand. But what would we do without black and white in our lives? That is why black and white are the primary colors used in the brand positioning.\nAs for my mother, she taught me to see this world as beautiful, introduced me to art, and supported me in all my wildest desires.\n\nAs for the idea, it came to my mind while looking through old family photos when I came across a picture of my mother. In the shot, she was only 16 years old and still living in a small village. But she was full of faith in herself and the future. And what struck me the most was her intense look, a whole of complete freedom. This image of identity is embodied in the brand.»\n\n-Galina Shtor, the brand owner",
		}
	},
	footer: {
		thanksSubscribe: {
			en: 'Thanks for subscribing',
			ua: 'Дякуємо за підписку'
		},
		tryAgain: {
			en: 'Try again',
			ua: 'Спробуйте ще',
		},
		stayInformed: {
			en: 'Stay informed about releases and special events',
			ua: 'Будь в курсі випусків і спеціальних подій',
		}
	},
	infoPages: {
		thankYou: {
			title: {
				ua: "Дякуємо за замовлення ❤️",
				en: "Thank you for ordering ❤️"
			},
			text: {
				ua: "Чекайте email з tracking номером.",
				en: "Wait for email with tracking number."
			},
		},
		thankYouSubscribe: {
			title: {
				ua: "Дякуємо за підписку ❤️",
				en: "Thank you for subscribing ❤️"
			},
			text: {
				ua: "Ви успішно підписалися на наші новини.",
				en: "You have successfully subscribed to our newsletter."
			},
		},
		care: {
			title: {
				ua: "Догляд",
				en: "Care"
			},
			text : {
				ua: <>
					<h2>Прикраси:</h2>
					<br></br>
					<p>- знімайте прикраси перед контактом з водою та початком процедур по догляду за шкірою обличчя та рук;</p>
					<p>- не розпилюйте парфуми, лак для волосся та інші хімічні речовини безпосередньо на виріб;</p>
					<p>- уникайте взаємодії виробу з лужними миючими засобами, речовинами, що містять хлор і йод, кремами та мазями;</p>
					<p>- після кожного використання прикрас необхідно їх протирати м’якою тканиною без ворсу;</p>
					<p>- зберігати прикраси потрібно в мішечках, окремо від інших;</p>
					<p>- намагайтесь щоб вироби не торкались будь-яких твердих матеріалів, таких як метал;</p>
					<p>- метал та природні камені необхідно чистити тільки сухою тканиною та м’якою щіточкою;</p>
					<p>- надягати прикраси слід в останню чергу - коли ви вже одягнені.</p>
					<h2>Одяг:</h2>
					<br></br>
					<p>- професійна делікатна хімчистка;</p>
					<p>- допускається делікатне ручне прання;</p>
					<p>- не можна замочувати вироби з деревиною більше ніж 10 хвилин;</p>
					<p>- не використовуйте агресивні хімічні речовини при пранні виробів з деревиною;</p>
					</>,
				en: <>
					<h2>Jewelry:</h2>
					<br></br>
					<p>- remove jewelry before contact with water and starting face and hand skin care procedures;</p>
					<p>- do not spray perfume, hairspray and other chemicals directly on the product;</p>
					<p>- avoid interaction of the product with alkaline detergents, substances containing chlorine and iodine, creams and ointments;</p>
					<p>- after each use of jewelry, it is necessary to wipe them with a soft, lint-free cloth;</p>
					<p>- jewelry should be stored in bags, separately from others;</p>
					<p>- try not to let the products touch any hard materials, such as metal;</p>
					<p>- when cleaning products, do not use water, any polishing agents that contain alcohol, or ultrasonic polishing agents;</p>
					<p>- metal and natural stones must be cleaned only with a dry cloth and a soft brush;</p>
					<p>- jewelry should be put on last - when you are already dressed.</p>
					<h2>Clothes:</h2>
					<br></br>
					<p>- professional dry cleaning;</p>
					<p>- delicate hand wash possible;</p>
					<p>- don’t soak clothes with wooden details for more than 10 minutes;</p>
					<p>- don’t use aggressive chemicals for cleaning clothes with wooden details;</p>
					</>
			}
		},
		paymentDelivery: {
			title: {
				ua: "Оплата і доставка",
				en: "Payment and delivery"
			},
			text: {
				ua: <>
					<p>Шановні клієнти,</p>
					<p>Зверніть увагу, що всі відправлення здійснюються з нашого складу в Одесі (Україна). У зв'язку з війною в нашій країні визначні зміни в термінах доставки. Виготовлення товару триватиме до 10 робочих днів. Зверніть увагу, що бренд не несе відповідальності за місцеві податки.</p>
					<ul>
						<li>ДОСТАВКА В РОСІЮ ТА БІЛОРУСЬ НЕ ДОСТУПНА. Молимося за мир! Стоїмо з Україною!</li>
					</ul>
					<p>Міжнародні замовлення:</p>
					<p>Усі міжнародні замовлення відправляються службою Укрпошта та DHL Express.</p>
					<ul>
						<li>Стандартна доставка - $30 (10-20 робочих днів)</li>
					</ul>
					<p>Україна:</p>
					<p>Всі замовлення по Україні будуть доставлені службою «Нова Пошта». Термін доставки під час воєнного стану 2-5 робочих днів.</p>
				</>,
				en: <>
					<p>Dear customers,</p>
					<p>Please note that all shipments are from our warehouse in Odesa (Ukraine). Due to the war in our country, there are slight changes in delivery terms.The production of the item will take till 10 business days. Please note that the brand is not responsible for local taxes.</p>
					<ul>
						<li>DELIVERY TO RUSSIA AND BELARUS IS NOT AVAILABLE. We are praying for peace!Stand with Ukraine!</li>
					</ul>
					<p>International orders:</p>
					<p>All international orders will be shipped by Ukrposhta service and DHL Express.</p>
					<ul>
						<li>Standard delivery - $30 (10-20 business days)</li>
					</ul>
					<p>Ukraine:</p>
					<p>All orders within Ukraine we will be delivered via the Nova Poshta service. The delivery time during martial law is 2-5 business days.</p>
				</>
			}
		},
		returnsExchange: {
			title: {
				ua: "Повернення та обмін",
				en: "Returns and Delivery"
			},
			text: {
				ua: <>
					<p>Обмін і повернення товару можливі протягом 14 днів після отримання замовлення. Для оформлення повернення або обміну товару, будь ласка, зв'яжіться з нами за адресою: info@shtor.com.ua</p>
					<p>Переконайтеся, що повернений товар не використовувався, він знаходиться в оригінальній упаковці та містить оригінальні бирки, цінники та всі додаткові вкладення.</p>
					<p>Ми залишаємо за собою право відмовити у поверненні або обміні, якщо товар не відповідає вищезазначеним правилам.</p>
					<ul>
						<li>Товари з розділу OUTLET та вироби, виготовлені за індивідуальними мірками, поверненню та обміну не підлягають.</li>
					</ul>
					<p>Ви можете скористатися будь-якою службою експрес-доставки для повернення (зверніть увагу, що термін доставки НЕ повинен перевищувати 10 днів). SHTOR не несе відповідальності за витрати на зворотну доставку та митні збори.</p>
					<p>Після відправлення посилки повідомте нам трек-номер посилки та залиште посилання на сайт відстеження.</p>
					<p>Будь-які суперечки, які не підпадають під політику повернення та обміну, підлягають індивідуальному вирішенню шляхом переговорів між залученими сторонами.</p>
				</>,
				en: <>
					<p>Exchange and return of products are possible within 14 days after receiving the order. To arrange a return or an exchange of goods, please contact us at: info@shtor.com.ua</p>
					<p>Make sure that the returned product has not been used, is placed in its original packaging, and contains original tags, price tags, and all additional attachments.</p>
					<p>We reserve the right to refuse returns or exchanges if the item does not comply with the above-mentioned rules.</p>
					<ul>
						<li>Goods from the OUTLET section and products made according to individual measurements are not subject to return and exchange.</li>
					</ul>
					<p>You can use any express delivery service for a return (please note that the delivery time should NOT exceed 10 days). SHTOR is not responsible for return shipping costs and customs duties.</p>
					<p>After dispatching the package, please let us know the tracking number of the parcel and leave a link to the tracking website.</p>
					<p>Any disputes which do not fall under the Returns and Exchange policy are subject to individual resolvement through negotiation between the parties involved.</p>
				</>
			}
		},
		privacyPolicy: {
			title: {
				ua: "Політика Конфіденційності",
				en: "Privacy Policy"
			},
			text: {
				ua: <>
					<p>SHTOR усвідомлює важливість захисту конфіденційності ваших персональних даних. Ми запровадили сувору політику та заходи безпеки для захисту інформації, яку ви нам надаєте.</p>
					<ol>
						<li>Дані збираються, коли ви робите покупки на нашому веб-сайті або використовуєте наші онлайн-сервіси</li>
					</ol>
					<p><strong>Контактна інформація:</strong> Якщо ви робите покупку онлайн, ми отримаємо ваше ім’я, адресу, адресу електронної пошти, номер телефону та країну проживання. Ми використовуватимемо вашу контактну інформацію, щоб:</p>
					<ul>
						<li>Обробляти ваші покупки/замовлення та будь-які повернення, обміни та скарги, які можуть виникнути щодо вашої покупки</li>
						<li>Спілкуватися з вами щодо вашої покупки, відповідати на будь-які запитання чи коментарі, які можуть виникнути щодо наших продуктів або послуг, і керувати ними. Юридична підстава полягає в тому, що обробка ваших даних необхідна для того, щоб ми могли виконати наші договірні зобов’язання перед вами за договором купівлі-продажу. Ми зберігатимемо ваші дані стільки часу, скільки необхідно для цієї мети. Ми також можемо обробляти ваші контактні дані, щоб інформувати вас електронною поштою, SMS, листами, телефоном, WeChat, Whatsapp та іншими соціальними мережами про наші спеціальні події чи акції. Ви маєте право відхилити наші маркетингові повідомлення в будь-який час, натиснувши посилання для скасування підписки в кожному повідомленні або зв’язавшись зі службою підтримки клієнтів за адресою info@shtor.com.ua</li>
					</ul>
					<p>Дані кредитної картки: Якщо ви робите покупку в Інтернеті та вирішуєте оплатити кредитною карткою, ви надасте інформацію про свою кредитну картку на нашому веб-сайті, щоб завершити покупку. Дані вашої кредитної картки будуть захищені за допомогою Global Sign Encryption. Усі платежі на сайті здійснюються платіжною системою.</p>
					<p><strong>Інформаційні листи</strong></p>
					<ul>
						<li><strong>Інформаційні листи</strong></li>
					</ul>
					<p>Якщо ви підписалися на одну з наших інформаційних бюлетенів, ми обробимо ваше ім’я, адресу електронної пошти, країну та інформацію про те, чи цікавитеся ви жіночим чи чоловічим одягом, з метою розсилки такого інформаційного бюлетеня. Ви маєте право скасувати підписку на нашу розсилку в будь-який час, натиснувши посилання для скасування підписки, яке міститься в кожній розсилці, або зв’язавшись зі службою підтримки клієнтів за адресою info@shtor.com.ua</p>
				</>,
				en: <>
					<p>SHTOR recognizes the importance of protecting the privacy of your personal data. We have instituted strict policies and security measures to protect the information you provide us.</p>
					<ol>
						<li>Data is collected when you shop on our website or use our online services</li>
					</ol>
					<p><strong>Contact details:</strong> If you make a purchase online we will collect your name, address, e-mail address, telephone number, and country of residence. We will use your contact information to:</p>
					<ul>
						<li>Process your purchase/orders and any returns, exchanges and complaints you may have relating to your purchase</li>
						<li>Communicate with you regarding your purchase and answer and administer any questions or comments you may have regarding our products or services. The legal basis is that it is necessary to process your data in order for us to be able to fulfill our contractual obligations to you under the purchase agreement. We will retain your data for as long as necessary for this purpose. We may also process your contact details to keep you informed, via email, SMS, letters, telephone, WeChat, Whatsapp and other social media, of our special events or promotions. You are entitled to reject our marketing messages at any time by clicking on the unsubscribe link included in each message or by contacting customer service at info@shtor.com.ua</li>
					</ul>
					<p>Credit card details: If you make a purchase online and choose to pay by credit card, you will provide your credit card information on our website to finalize your purchase. Your credit card details will be protected using Global Sign Encryption. All the payments on the website are carried out by Payment System.</p>
					<p>Newsletter</p>
					<ul>
						<li><strong>Newsletter</strong></li>
					</ul>
					<p>If you have subscribed to one of our newsletters, we will process your name, email address, country and information on whether you are interested in womenswear or menswear for the purpose of sending out such a newsletter. You are entitled to unsubscribe to our newsletter at any time by clicking on the unsubscribe link included in each newsletter or by contacting customer service at info@shtor.com.ua </p>
				</>
			}
		},
		publicOffer: {
			title: {
				ua: "",
				en: ""
			},
			text: {
				ua: <>
						<p>ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ</p>
						<h4>1. Терміни та визначення</h4>
						<ul className="publicOffer-ul">
							<li>1.1. Продавець — ФОП Штор Галина Олександрівна.</li>
							<li>1.2. Покупець — будь-яка юридична особа, яка отримує інформацію від продавця, розміщує замовлення та купує у ФОП Штор Галини Олександрівни товари, представлені на вебсайті https://shtor.com.ua, для цілей, пов’язаних із здійсненням підприємницької діяльності.</li>
							<li>1.3. Товар — об’єкт угоди сторін, який був обраний покупцем на вебсайті інтернет-магазину та поміщений у кошик, або вже придбаний покупцем у продавця дистанційно.</li>
							<li>1.4. Інтернет-магазин — офіційний інтернет-магазин продавця ФОП Штор Галини Олександрівни, розташований за інтернет-адресою https://shtor.com.ua, створений для укладення договорів роздрібного та оптового продажу на підставі ознайомлення покупця з описом товару, запропонованого продавцем, за допомогою Інтернету.</li>
						</ul>
						<h4>2. Загальні положення</h4>
						<ul className="publicOffer-ul">
							<li>2.1. Цей договір є офіційною та публічною офертою продавця укласти договір купівлі-продажу товарів, представлених на вебсайті https://shtor.com.ua. Цей договір є публічним, тобто відповідно до статті 633 Цивільного кодексу України його умови однакові для всіх покупців незалежно від їх статусу (фізична особа, юридична особа, фізична особа-підприємець) без надання переваги одному покупцеві перед іншим. Укладаючи цей договір, клієнт повністю приймає умови та порядок розміщення замовлення, оплати товару, доставки товару, повернення товару, відповідальності за недобросовісне замовлення та всі інші умови договору.</li>
							<li>2.2. Договір, укладений на підставі акцепту покупцем цієї оферти, є договором приєднання, до якого покупець приєднується без будь-яких винятків та/або застережень.</li>
							<li>2.3. Продавець має право вносити зміни до цих умов без попереднього повідомлення. Зміни до умов набувають чинності після їх публікації на вебсайті https://shtor.com.ua і застосовуються до будь-якого замовлення, розміщеного після публікації.</li>
						</ul>
						<h4>3. Предмет договору</h4>
						<ul className="publicOffer-ul">
							<li>3.1. Продавець зобов’язується передати товар покупцеві, а покупець зобов’язується сплатити та прийняти товар на умовах цього договору.</li>
							<li>3.2. Договір вважається укладеним з моменту натискання кнопки "Підтвердити замовлення" на сторінці оформлення замовлення в розділі "Кошик" та отримання покупцем підтвердження замовлення від продавця в електронному вигляді. </li>
						</ul>
						<h4>4. Розміщення замовлення</h4>
						<ul className="publicOffer-ul">
							<li>4.1. Факт розміщення замовлення покупцем є беззаперечним фактом прийняття покупцем умов цього договору. Покупець, який придбав товар у інтернет-магазині продавця (який розмістив замовлення на товар), вважається особою, яка вступила у відносини з продавцем на умовах цього договору.</li>
							<li>4.2. Покупець надає згоду на збір, обробку та передачу особистих даних, дозвіл на обробку персональних даних є дійсним протягом усього строку дії договору, а також протягом необмеженого строку після його закінчення. Крім того, укладаючи договір, покупець підтверджує, що він був повідомлений (без додаткового повідомлення) про права, встановлені Законом України "Про захист персональних даних", про мету збору даних, а також про те, що його особисті дані передаються продавцю з метою виконання умов цього договору, можливості взаємних розрахунків.</li>
							<li>4.3. Після розміщення замовлення клієнт отримує інформацію про своє замовлення на вказану ним електронну адресу із зазначенням вартості товару, адреси та вартості доставки.</li>
							<li>4.4. Продавець залишає за собою право скасувати заявку клієнта на етапі підтвердження заявки.</li>
							<li>4.5. При розміщенні замовлення на вебсайті інтернет-магазину покупець зобов'язується надати обов’язкову інформацію, необхідну для продавця для виконання замовлення.</li>
							<li>4.6. Якщо будь-яка зі сторін договору потребує додаткової інформації, він має право запросити її у іншої сторони. Якщо покупець не надає необхідну інформацію, продавець не несе відповідальності за надання якісного обслуговування покупцеві при купівлі товарів в інтернет-магазині.</li>
							<li>4.7. Покупець несе відповідальність за достовірність наданої інформації при розміщенні замовлення.</li>
							<li>4.8. Після розміщення замовлення покупцеві надається інформація про дату доставки замовлення. Зазначена дата залежить від наявності замовлених товарів на складі продавця та часу, необхідного для обробки та доставки замовлення.</li>
						</ul>
						<h4>5. Ціна та доставка товарів</h4>
						<ul className="publicOffer-ul">
							<li>5.1. Ціна товару вказується в українських гривнях.</li>
							<li>5.2. Ціни на товари та послуги можуть змінюватися продавцем в односторонньому порядку залежно від ринкової ситуації, при цьому ціна однієї одиниці товару, вартість якого була повністю сплачена покупцем, не може бути змінена продавцем в односторонньому порядку.</li>
							<li>5.3. Обов'язок покупця сплатити за товар вважається виконаним з моменту отримання продавцем коштів на свій рахунок.</li>
							<li>5.4. Ціна товару, вказана на вебсайті інтернет-магазину, не включає вартість доставки товару до покупця. Покупець сплачує вартість доставки товару відповідно до чинних тарифів на послуги доставки (перевізників) безпосередньо обраній ним службі доставки (перевізнику).</li>
							<li>5.5. Розрахунки між продавцем і покупцем за товари здійснюються методами, зазначеними на вебсайті інтернет-магазину у розділі "Доставка і оплата".</li>
							<li>5.6. Право власності та ризик випадкової втрати або пошкодження товару переходить до покупця або його представника з моменту отримання покупцем товару у місті доставки товару при самостійній доставці товару від продавця, або з моменту передачі продавцем товару обраній покупцем службі доставки (перевізнику).</li>
							<li>5.7. Продавець намагається максимально дотримуватись узгоджених термінів доставки. Продавець не несе відповідальності за можливі затримки в доставці через непередбачувані обставини, що виникли не з вини продавця, які не могли бути передбачені.</li>
							<li>5.8. Для клієнтів з країн далекого зарубіжжя продавець відправляє замовлення без вирахування податків і мит покупців країн. Ціна доставки не включає податки та мита.</li>
						</ul>
						<h4>6. Права та обов'язки сторін</h4>
						<ul className="publicOffer-ul">
							<li>6.1. Продавець зобов’язується:</li>
							<li>6.1.1. Доставити товар покупцеві відповідно до умов цього договору та замовлення покупця.</li>
							<li>6.1.2. Не розголошувати будь-яку приватну інформацію про покупця та не надавати доступ до цієї інформації третім особам, за винятком випадків, передбачених законодавством, та під час виконання замовлення покупця.</li>
							<li>6.2. Продавець має право:</li>
							<li>6.2.1 Змінювати умови цього договору, а також ціни на товари та послуги, в односторонньому порядку, шляхом розміщення їх на веб-сайті інтернет-магазину. Всі зміни набирають чинності з моменту їх опублікування.</li>
							<li>6.3 Покупець зобов'язується:</li>
							<li>6.3.1 Перед укладенням договору ознайомитися зі змістом договору, умовами договору та цінами, запропонованими продавцем на веб-сайті інтернет-магазину.</li>
							<li>6.3.2 Для того, щоб продавець виконав свої зобов'язання перед покупцем, останній повинен надати всі необхідні дані, які однозначно ідентифікують його як покупця та є достатніми для доставки замовлення покупцю. </li>
						</ul>
						<h4>7. Умови повернення та обміну</h4>
						<ul className="publicOffer-ul">
							<li>7.1. Покупець має право повернути товар належної якості продавцю, у якого він був придбаний, якщо товар не задовольнив його за формою, габаритами, фасоном, кольором або розміром чи з інших причин не може бути використаний ним за призначенням. Покупець має право на обмін або повернення протягом 14 (чотирнадцяти) днів з моменту оформлення замовлення, за умови збереження товарного виду товару, всіх ярликів, пломб та оригінальної упаковки. А також розрахункового документа, виданого покупцю для оплати товару. Перелік товарів, що не підлягають поверненню на підставах, передбачених цим пунктом, затверджується Кабінетом Міністрів України.</li>
							<li>7.2. Покупцеві буде відшкодована вартість товару належної якості протягом 10 (десяти) банківських днів з моменту отримання такого товару продавцем, за умови дотримання вимог, передбачених п. 7.1. договору, чинного законодавства України.</li>
							<li>7.3. Вартість товару підлягає поверненню шляхом безготівкового перерахування на рахунок покупця.</li>
							<li>7.4. Вартість доставки обміну або повернення товару належної якості оплачується покупцем.</li>
							<li>7.5. Не підлягають обміну або поверненню:- товари, що були у вжитку- товари, що пройшли хімчистку або прання- товари, з яких були зрізані ярлики та етикетки- товари, що були використані для фото/відеозйомки- товари, що були виготовлені з урахуванням індивідуальних особливостей- товари, доставка яких була здійснена за межі території України (міжнародна доставка)- подарункові сертифікати.</li>
							<li>7.6. Продавець має право відмовити покупцеві у поверненні товарів, які, на його суб'єктивну думку та незалежну експертизу, не відповідають вимогам, зазначеним у п. 7.5.</li>
						</ul>
						<h4>8. Відповідальність сторін</h4>
						<ul className="publicOffer-ul">
							<li>8.1. Продавець та покупець несуть відповідальність за виконання своїх зобов'язань згідно з чинним законодавством України та положеннями цього договору.</li>
							<li>8.2. Продавець або покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов'язань, якщо невиконання є результатом таких форс-мажорних обставин, як: війна або військові дії, землетрус, повінь, пожежа та інші природні катастрофи, що сталися незалежно від волі продавця або покупця після укладення цього договору. Сторона, яка не може виконати свої зобов'язання, повинна негайно повідомити про це іншу сторону.</li>
						</ul>
						<h4>9. Конфіденційність та захист персональних даних</h4>
						<ul className="publicOffer-ul">
							<li>9.1. Надаючи персональні дані на веб-сайті інтернет-магазину при реєстрації або оформленні замовлення, покупець надає продавцеві свою добровільну згоду на обробку, використання (включаючи передачу) його персональних даних. А також здійснення інших дій, передбачених Законом України "Про захист персональних даних", без обмеження строку дії такої згоди.</li>
							<li>9.2. Продавець зобов'язується не розголошувати отриману від покупця інформацію. Не вважається порушенням надання продавцем інформації контрагентам та третім особам, які діють на підставі договору з продавцем, включаючи виконання зобов'язань перед покупцем, а також у випадках, коли розкриття такої інформації встановлено вимогами чинного законодавства України.</li>
							<li>9.3. Покупець відповідає за актуальність своїх персональних даних. Продавець не несе відповідальності за неналежне виконання або невиконання своїх зобов'язань через неактуальність інформації про покупця або її невідповідність. </li>
						</ul>
						<h4>10. Інші умови</h4>
						<ul className="publicOffer-ul">
							<li>10.1. Цей договір укладено на території України і діє відповідно до чинного законодавства України.</li>
							<li>10.2. Всі спори, що виникають між покупцем та продавцем, вирішуються шляхом переговорів. У разі недосягнення згоди щодо спірного питання шляхом переговорів, покупець та/або продавець мають право звернутися для вирішення спору до судових органів згідно з чинним законодавством України.</li>
						</ul>
					</>,
				en: 
					<>
						<p>PUBLIC OFFER AGREEMENT</p>
						<h4>1. Terms and definitions</h4>
						<ul className="publicOffer-ul">
							<li>1.1. The seller is FOP Shtor Galina Olexandrivna.</li>
							<li>1.2. The buyer is any legal entity that receives information from the seller, places an order and buys goods presented on the website https://shtor.com.ua from the FOP Shtor Galina Olexandrivna, for purposes related to the implementation of business activities.</li>
							<li>1.3. The product is the object of the agreement of the parties, which was selected by the buyer on the website of the online store and placed in the basket, or already purchased by the buyer from the seller remotely.</li>
							<li>1.4. The online store is the official online store of the seller FOP Shtor Galina Olexandrivna, located at the Internet address https://shtor.com.ua, created for the conclusion of retail and wholesale contracts based on the buyer's familiarization with the description of the goods offered by the seller, using the Internet.</li>
						</ul>
						<h4>2. General provisions</h4>
						<ul className="publicOffer-ul">
							<li>2.1. This contract is an official and public offer of the seller to conclude a contract of sale of goods presented on the website https://shtor.com.ua. This contract is public, that is, according to Article 633 of the Civil Code of Ukraine, its conditions are the same for all buyers regardless of their status (individual, legal entity, individual entrepreneur) without giving preference to one buyer over another. By concluding this contract, the client fully accepts the terms and conditions of order placement, payment for goods, delivery of goods, return of goods, liability for fraudulent orders and all other terms of the contract.</li>
							<li>2.2. The contract concluded on the basis of the buyer's acceptance of this offer is a contract of accession, to which the buyer joins without any exceptions and/or reservations.</li>
							<li>2.3. The seller has the right to make changes to these conditions without prior notice. Changes to the conditions become effective after their publication on the website https://shtor.com.ua and apply to any order placed after publication.</li>
						</ul>
						<h4>3. Subject of the contract</h4>
						<ul className="publicOffer-ul">
							<li>3.1. The seller undertakes to hand over the goods to the buyer, and the buyer undertakes to pay and accept the goods under the terms of this contract.</li>
							<li>3.2. The contract is considered concluded from the moment the "Confirm order" button is clicked on the checkout page in the "Basket" section and the buyer receives the order confirmation from the seller in electronic form.</li>
						</ul>
						<h4>4. Placing an order</h4>
						<ul className="publicOffer-ul">
							<li>4.1. The fact of placing an order by the buyer is an indisputable fact that the buyer accepts the terms of this contract. The buyer who purchased the goods in the seller's online store (who placed an order for the goods) is considered a person who has entered into relations with the seller under the terms of this contract.</li>
							<li>4.2. The buyer consents to the collection, processing and transfer of personal data, the permission to process personal data is valid for the entire term of the contract, as well as for an unlimited period after its termination. In addition, by concluding the contract, the buyer confirms that he has been informed (without additional notification) of the rights established by the Law of Ukraine "On the Protection of Personal Data", about the purpose of data collection, as well as that his personal data is transferred to the seller for the purpose of fulfilling terms of this contract, the possibility of mutual settlements.</li>
							<li>4.3. After placing the order, the client receives information about his order to the e-mail address specified by him, indicating the price of the product, the address and the cost of delivery.</li>
							<li>4.4. The seller reserves the right to cancel the client's application at the stage of application confirmation.</li>
							<li>4.5. When placing an order on the website of the online store, the buyer undertakes to provide mandatory information necessary for the seller to fulfill the order.</li>
							<li>4.6. If any of the parties to the contract needs additional information, he has the right to request it from the other party. If the buyer does not provide the necessary information, the seller is not responsible for providing quality service to the buyer when purchasing goods in the online store.</li>
							<li>4.7. The buyer is responsible for the accuracy of the information provided when placing the order.</li>
							<li>4.8. After placing the order, the buyer is provided with information about the delivery date of the order. The specified date depends on the availability of the ordered goods in the seller's warehouse and the time required to process and deliver the order.</li>
						</ul>
						<h4>5. Price and delivery of goods</h4>
						<ul className="publicOffer-ul">
							<li>5.1. The price of the product is indicated in Ukrainian hryvnias.</li>
							<li>5.2. The prices of goods and services can be changed by the seller unilaterally depending on the market situation, while the price of one unit of the product, the cost of which has been fully paid by the buyer, cannot be changed by the seller unilaterally.</li>
							<li>5.3. The buyer's obligation to pay for the goods is considered fulfilled from the moment the seller receives the funds to his account.</li>
							<li>5.4. The price of the product indicated on the website of the online store does not include the cost of delivery of the product to the buyer. The buyer pays the cost of delivery of goods in accordance with the applicable tariffs for delivery services (carriers) directly to the delivery service (carrier) chosen by him.</li>
							<li>5.5. Settlements between the seller and the buyer for goods are made by the methods specified on the website of the online store in the "Delivery and payment" section.</li>
							<li>5.6. The right of ownership and the risk of accidental loss or damage to the goods passes to the buyer or his representative from the moment the buyer receives the goods in the city of delivery of the goods in case of independent delivery of the goods from the seller, or from the moment the seller hands over the goods to the delivery service (carrier) chosen by the buyer.</li>
							<li>5.7. The seller tries to adhere to the agreed delivery terms as much as possible. The seller is not responsible for possible delays in delivery due to unforeseen circumstances that are not the fault of the seller and could not be foreseen.</li>
							<li>5.8. For customers from countries far abroad, the seller ships the order without deducting taxes and duties of the buyer's countries. The shipping price does not include taxes and duties.</li>
						</ul>
						<h4>6. Rights and obligations of the parties</h4>
						<ul className="publicOffer-ul">
							<li>6.1. The seller undertakes:</li>
							<li>6.1.1. Deliver the goods to the buyer in accordance with the terms of this contract and the buyer's order.</li>
							<li>6.1.2. Not to disclose any private information about the buyer and not to provide access to this information to third parties, except as provided by law and during the fulfillment of the buyer's order.</li>
							<li>6.2. The seller has the right to:</li>
							<li>6.2.1 Change the terms of this contract, as well as the prices of goods and services, unilaterally, by posting them on the website of the online store. All changes take effect from the moment of their publication.</li>
							<li>6.3 The buyer undertakes:</li>
							<li>6.3.1 Before concluding the contract, familiarize yourself with the content of the contract, the terms of the contract and the prices offered by the seller on the website of the online store.</li>
							<li>6.3.2 In order for the seller to fulfill his obligations to the buyer, the latter must provide all necessary data that uniquely identify him as the buyer and are sufficient to deliver the order to the buyer. </li>
						</ul>
						<h4>7. Terms of return and exchange</h4>
						<ul className="publicOffer-ul">
							<li>7.1. The buyer has the right to return the goods of proper quality to the seller from whom they were purchased, if the goods did not satisfy him in terms of shape, dimensions, style, color or size or for other reasons cannot be used by him for their intended purpose. The buyer has the right to exchange or return within 14 (fourteen) days from the moment of placing the order, provided that the product type, all labels, seals and original packaging are preserved. As well as the settlement document issued to the buyer to pay for the goods. The list of goods that are not subject to return on the grounds provided for in this paragraph is approved by the Cabinet of Ministers of Ukraine.</li>
							<li>7.2. The buyer will be reimbursed for the cost of goods of proper quality within 10 (ten) banking days from the moment of receipt of such goods by the seller, subject to compliance with the requirements stipulated in clause 7.1. contract, current legislation of Ukraine.</li>
							<li>7.3. The cost of the product is subject to refund by non-cash transfer to the buyer's account.</li>
							<li>7.4. The cost of delivery of an exchange or return of a good quality product is paid by the buyer.</li>
							<li>7.5. Not subject to exchange or return:- used goods- dry-cleaned or laundered goods- goods from which tags and labels have been cut- goods that were used for photo/video shooting- goods that were manufactured taking into account individual characteristics- goods, the delivery of which was made outside the territory of Ukraine (international delivery)- hats and gloves- gift cards.</li>
							<li>7.6. The seller has the right to refuse the buyer the return of goods that, in his subjective opinion and independent examination, do not meet the requirements specified in clause 7.5 </li>
						</ul>
						<h4>8. Liability of the parties</h4>
						<ul className="publicOffer-ul">
							<li>8.1. The seller and the buyer are responsible for fulfilling their obligations in accordance with the current legislation of Ukraine and the provisions of this contract.</li>
							<li>8.2. The seller or the buyer is released from responsibility for full or partial non-fulfillment of their obligations, if the non-fulfillment is the result of such force majeure circumstances as: war or hostilities, earthquake, flood, fire and other natural disasters that occurred regardless of the will of the seller or the buyer after concluding this contract. A party that cannot fulfill its obligations must immediately notify the other party.</li>
						</ul>
						<h4>9. Confidentiality and protection of personal data</h4>
						<ul className="publicOffer-ul">
							<li>9.1. By providing personal data on the website of the online store when registering or placing an order, the buyer gives the seller his voluntary consent to the processing, use (including transfer) of his personal data. As well as the implementation of other actions provided for by the Law of Ukraine "On the Protection of Personal Data", without limiting the term of validity of such consent.</li>
							<li>9.2. The seller undertakes not to disclose the information received from the buyer. It is not considered a violation for the seller to provide information to counterparties and third parties acting on the basis of the contract with the seller, including the fulfillment of obligations to the buyer, as well as in cases where the disclosure of such information is established by the requirements of the current legislation of Ukraine.</li>
							<li>9.3. The buyer is responsible for the relevance of his personal data. The seller is not responsible for improper performance or non-performance of its obligations due to the irrelevance of information about the buyer or its inconsistency. </li>
						</ul>
						<h4>10. Other conditions</h4>
						<ul className="publicOffer-ul">
							<li>10.1. This contract is concluded on the territory of Ukraine and is valid in accordance with the current legislation of Ukraine.</li>
							<li>10.2. All disputes arising between the buyer and the seller are resolved through negotiations. In the event of failure to reach an agreement on a disputed issue through negotiations, the buyer and/or seller have the right to apply to the judicial authorities for resolution of the dispute in accordance with the current legislation of Ukraine.</li>
						</ul>
					</>
			}
		},
		sizeGuide: {
			title: {
				ua: "Розмірна сітка",
				en: "Size guide"
			},
			size: {
				ua: "РОЗМІР",
				en: "SIZE"
			},
			bust: {
				ua: "ОБ’ЄМ БЮСТУ",
				en: "BUST"
			},
			waist: {
				ua: "ОБ’ЄМ ТАЛІЇ",
				en: "WAIST"
			},
			hips: {
				ua: "ОБ’ЄМ СТЕГОН",
				en: "HIPS"
			}
		}
	},
	product: {
		description: {
			ua: "Опис",
			en: "Description",
		},
		silverAttachDesc: {
			ua: "Срібний ланцюг з регульованою довжіною 65см і 80см\n",
			en: "Silver chain with adjustable length 65cm and 80cm\n",
		},
		size: {
			title: {
				ua: "Розмір",
				en: "Size",
			},
			cm: {
				ua: "см",
				en: "cm",
			}
		},
		main: {
			ua: 'Головна',
			en: 'Home'
		},
		currency: {
			ua: '₴',
			en: '$'
		},
		color: {
			"Black": {
				ua: 'Чорний',
				en: 'Black'
			},
			"Dark Brown": {
				ua: 'Темно Коричневий',
				en: 'Dark Brown'
			},
			"Brown": {
				ua: 'Коричневий',
				en: 'Brown'
			},
		},
		attachment: {
			"Без підвісу": {
				ua: 'Без підвісу',
				en: 'No attachment'
			},
			"Срібний ланцюг": {
				ua: 'Срібний ланцюг',
				en: 'Silver chain'
			},
			"Срібна орбіта": {
				ua: 'Срібна орбіта',
				en: 'Silver orbit'
			},
		},
		addToCart: {
			ua: 'Додати у кошик',
			en: 'Add to cart'
		},
		outOfStock: {
			ua: 'Немає в наявності',
			en: 'Out of stock',
		},
	},
	cart: {
		title: {
			ua: 'Кошик',
			en: 'Cart'
		},
		delete: {
			ua: 'Ви впевнені, що хочете видалити',
			en: 'Are you sure you want to delete'
		},
		copy: {
			ua: 'Ви впевнені, що хочете скопіювати',
			en: 'Are you sure you want to copy'
		},
		deliveryHint: {
			ua: 'Доставка, податки та коди знижки розраховуються під час оформлення замовлення.',
			en: 'Shopping,taxes and discount codes calculated at checkout.',
		},
		fromCart: {
			ua: 'з вашого кошика ?',
			en: 'from your cart ?',
		},
		empty: {
			ua: 'Ваш кошик зараз порожній',
			en: 'Your cart is currently empty'
		},
		continueShopping: {
			ua: 'Продовжити покупки',
			en: 'Continue shopping'
		},
		subTotal: {
			ua: 'Проміжний підсумок',
			en: 'Subtotal'
		},
		total: {
			ua: 'Всього',
			en: 'Total'
		},
		checkOut: {
			ua: 'Чек-аут',
			en: 'Check out'
		},
		deliveryAlert: {
			ua: 'Доставка, податки та коди знижки розраховуються під час оформлення замовлення.',
			en: 'Shopping,taxes and discount codes calculated at checkout.'
		},
		quantity: {
			ua: 'Кількість',
			en: 'Quantity'
		},
		delivery: {
			ua: 'Доставка',
			en: 'Shipping',
		},
		internationalDeliveryPrice: {
			ua: 1125,
			en: 30,
		},
		back: {
			ua: "Назад",
			en: "Back",
		},
		free: {
			ua: "Безкоштовно",
			en: "Free",
		},
		countryRegion: {
			ua: "Країна/Регіон",
			en: "Country/Region",
		},
		firstName: {
			ua: "Ім'я",
			en: "First Name",
		},
		lastName: {
			ua: "Прізвище",
			en: "Last Name",
		},
		address: {
			ua: "Адреса",
			en: "Address",
		},
		apartment: {
			ua: "Квартира, кабінет і т. д.",
			en: "Apartment, suite, etc.",
		},
		addApartment: {
			ua: "Додати квартиру, кабінет і т. д.",
			en: "Add apartment, suite, etc.",
		},
		postalCode: {
			ua: "Поштовий код / ВІДДІЛЕННЯ НОВОЇ ПОШТИ (УКРАЇНА)",
			en: "Postal code / NEW POST OFFICE (UKRAINE)",
		},
		city: {
			ua: "Місто",
			en: "City",
		},
		phone: {
			ua: "Телефон",
			en: "Phone",
		},
		shippingMethod: {
			ua: "Спосіб доставки",
			en: "Shipping method",
		},
		novaPost: {
			ua: "Нова Пошта (доставка тільки по Україні)",
			en: "NOVA POST (only for Ukraine shipping)",
		},
		billingAddress: {
			ua: "Платіжна адреса",
			en: "Billing address",
		},
		sameAsShipping: {
			ua: "Та ж, що й доставка",
			en: "Same as shipping address",
		},
		useDifferentBilling: {
			ua: "Використовувати іншу платіжну адресу",
			en: "Use a different billing address",
		},
		attachment: {
			ua: (attachment) => {
				if (attachment === 'Срібна орбіта') return "зі срібною орбітою"
				if (attachment === 'Срібний ланцюг') return "зі срібним ланцюгом"
				return ""
			},
			en: (attachment) => {
				if (attachment === 'Срібна орбіта') return "with silver orbit"
				if (attachment === 'Срібний ланцюг') return "with silver chain"
				return ""
			},
		}
	},
	yes: {
		ua: "Так",
		en: "Yes",
	},
	no: {
		ua: "Ні",
		en: "No",
	},
	money: {
		eur: 'EUR',
		usd: 'USD',
		uah: 'UAH',
	},
	currencySymbol: {
		eur: '€',
		usd: '$',
		uah: '₴',
	},
}

export const countries = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"Andorra",
	"Angola",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bhutan",
	"Bolivia",
	"Bosnia and Herzegovina",
	"Botswana",
	"Brazil",
	"Brunei",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Colombia",
	"Comoros",
	"Congo",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"East Timor (Timor-Leste)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Fiji",
	"Finland",
	"France",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Greece",
	"Grenada",
	"Guatemala",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Honduras",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran",
	"Iraq",
	"Ireland",
	"Israel",
	"Italy",
	"Ivory Coast",
	"Jamaica",
	"Japan",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Kosovo",
	"Kuwait",
	"Kyrgyzstan",
	"Laos",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Mauritania",
	"Mauritius",
	"Mexico",
	"Micronesia",
	"Moldova",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Morocco",
	"Mozambique",
	"Myanmar (Burma)",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"North Korea",
	"North Macedonia (Macedonia)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Poland",
	"Portugal",
	"Qatar",
	"Romania",
	"Russia",
	"Rwanda",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Korea",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Sweden",
	"Switzerland",
	"Syria",
	"Taiwan",
	"Tajikistan",
	"Tanzania",
	"Thailand",
	"Togo",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates (UAE)",
	"United Kingdom (UK)",
	"United States of America (USA)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Vatican City (Holy See)",
	"Venezuela",
	"Vietnam",
	"Yemen",
	"Zambia",
	"Zimbabwe"
];
